import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Code, Zap, Star, Cpu, Globe, Rocket, ArrowRight, ChevronDown } from 'lucide-react';
import { Tilt } from 'react-tilt';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const LandingPage = () => {
    return (
        <div className="min-h-screen text-gray-900 dark:text-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
                <HeroSection />
                <TimelineSection />
                <CTASection />
            </div>
        </div>
    );
};

const HeroSection = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.section
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 }
            }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-center mb-32 sm:mb-48"
        >
            <motion.h1
                className="text-5xl sm:text-7xl lg:text-8xl font-black mb-8 leading-tight"
                variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 20 }
                }}
            >
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 dark:from-blue-400 dark:via-purple-400 dark:to-pink-400">
                    Ihre Vision,
                </span>
                <br />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-600 dark:from-pink-400 dark:via-purple-400 dark:to-blue-400">
                    Unsere Expertise
                </span>
            </motion.h1>
            <motion.p
                className="text-2xl sm:text-3xl lg:text-4xl mb-12 text-gray-700 dark:text-gray-300 font-light italic"
                variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 20 }
                }}
            >
                "Wo andere Websites bauen, komponieren wir digitale Symphonien."
            </motion.p>
            <motion.div
                variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 20 }
                }}
            >
                <a
                    href="#timeline"
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300 animate-bounce"
                >
                    <ChevronDown className="w-6 h-6" />
                </a>
            </motion.div>
        </motion.section>
    );
};

const TimelineSection = () => {
    const steps = [
        { icon: Code, title: "Vision", description: "Ihre Ideen, unser Ausgangspunkt." },
        { icon: Zap, title: "Strategie", description: "Maßgeschneiderte Lösungen entwickeln." },
        { icon: Star, title: "Design", description: "Ästhetik trifft Funktionalität." },
        { icon: Cpu, title: "Entwicklung", description: "Innovative Technologien im Einsatz." },
        { icon: Globe, title: "Optimierung", description: "SEO für maximale Reichweite." },
        { icon: Rocket, title: "Launch", description: "Ihr Erfolg hebt ab!" },
    ];

    return (
        <motion.section
            id="timeline"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="mb-32 sm:mb-48"
        >
            <h2 className="text-4xl sm:text-5xl font-bold mb-16 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 dark:from-blue-400 dark:via-purple-400 dark:to-pink-400">
                Unser Prozess
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12">
                {steps.map((step, index) => (
                    <TimelineStep key={index} step={step} index={index} />
                ))}
            </div>
        </motion.section>
    );
};

interface Step {
    icon: React.ElementType;
    title: string;
    description: string;
}

interface TimelineStepProps {
    step: Step;
    index: number;
}

const TimelineStep: React.FC<TimelineStepProps> = ({ step, index }) => {
    const Icon = step.icon;
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 }
            }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
        >
            <Tilt options={{ max: 25, scale: 1.05 }}>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 p-[2px] rounded-2xl shadow-xl"
                >
                    <div className="bg-white dark:bg-gray-800 p-6 rounded-2xl h-full flex flex-col justify-between">
                        <div>
                            <div className="flex items-center mb-4">
                                <div className="w-12 h-12 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full flex items-center justify-center text-white mr-4">
                                    <Icon className="w-6 h-6" />
                                </div>
                                <h3 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
                                    {step.title}
                                </h3>
                            </div>
                            <p className="text-gray-600 dark:text-gray-300 mb-4">{step.description}</p>
                        </div>
                        <CountUp
                            end={index + 1}
                            duration={2}
                            delay={0.5 + index * 0.1}
                            className="text-4xl font-bold text-blue-500"
                        />
                    </div>
                </motion.div>
            </Tilt>
        </motion.div>
    );
};

const CTASection = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.section
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 }
            }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16 sm:mb-24"
        >
            <h2 className="text-5xl sm:text-6xl lg:text-7xl font-black mb-8 sm:mb-12 leading-tight">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 dark:from-blue-400 dark:via-purple-400 dark:to-pink-400">
                    Bereit für die
                </span>
                <br />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-600 dark:from-pink-400 dark:via-purple-400 dark:to-blue-400">
                    digitale Zukunft?
                </span>
            </h2>
            <p className="max-w-3xl mx-auto text-xl sm:text-2xl text-gray-700 dark:text-gray-300 mb-12 sm:mb-16 leading-relaxed">
                Lassen Sie uns gemeinsam Ihre Vision in die Realität umsetzen. Kontaktieren Sie uns noch heute für ein
                unverbindliches Gespräch über Ihr Projekt.
            </p>
            <motion.a
                href="#kontakt"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-flex items-center bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 hover:from-blue-600 hover:via-purple-600 hover:to-pink-600 text-white font-bold py-4 px-8 sm:py-5 sm:px-10 rounded-full transition-all transform hover:shadow-xl text-lg sm:text-xl"
            >
                Jetzt durchstarten
                <ArrowRight className="w-6 h-6 ml-2" />
            </motion.a>
        </motion.section>
    );
};

export default LandingPage;