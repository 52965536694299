import React, { useState, useEffect } from 'react';
import { AlertCircle, Check, ChevronRight, Clock, PhoneCall, Settings, Star, ArrowRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const PricingPage = () => {
    const [hoveredPlan, setHoveredPlan] = useState<number | null>(null);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScrollY(window.scrollY);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const plans = [
        {
            name: 'Starter',
            price: '€29',
            features: ['5 GB Speicherplatz', '1 Domain', 'SSL-Zertifikat', 'E-Mail-Support'],
            recommended: false,
            cta: 'Jetzt starten'
        },
        {
            name: 'Professional',
            price: '€59',
            features: ['20 GB Speicherplatz', '3 Domains', 'SSL-Zertifikat', 'Tägliches Backup', 'Telefon-Support'],
            recommended: true,
            cta: 'Profi werden'
        },
        {
            name: 'Enterprise',
            price: '€99',
            features: ['100 GB Speicherplatz', 'Unbegrenzte Domains', 'SSL-Zertifikat', 'Stündliches Backup', 'Dedizierter Support', 'Performance-Optimierung'],
            recommended: false,
            cta: 'Kontaktieren Sie uns'
        }
    ];


    const buttonClasses = "px-6 py-3 rounded-full transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-lg hover:shadow-xl text-sm font-semibold";
    const primaryButtonClasses = `${buttonClasses} bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white hover:from-violet-600 hover:to-fuchsia-600 focus:ring-violet-400`;
    const secondaryButtonClasses = `${buttonClasses} bg-gray-100 text-gray-800 hover:bg-gray-200 focus:ring-gray-400 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700`;

    return (
        <div className="min-h-screen py-16 px-4 sm:px-6 lg:px-8 transition-colors duration-300">
            <motion.div
                className="max-w-7xl mx-auto"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.8}}
            >
                <motion.div
                    className="text-center mb-20"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 0.2, duration: 0.5}}
                >
                    <h2 className="text-center">
                        <motion.span
                            className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 dark:from-blue-400 dark:via-purple-400 dark:to-pink-400"
                            initial={{opacity: 0, y: -20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.8}}
                        >
                            Preiswerte
                        </motion.span>
                        <br/>
                        <motion.span
                            className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-600 dark:from-pink-400 dark:via-purple-400 dark:to-blue-400"
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.8, delay: 0.2}}
                        >
                            Lösungen
                        </motion.span>
                    </h2>
                    <motion.p  className="max-w-3xl mt-10 mx-auto text-lg sm:text-xl lg:text-2xl text-gray-600 dark:text-gray-400 mb-12">
                        Maßgeschneiderte Websites, die Ihr Unternehmen in die digitale Zukunft katapultieren. Starten
                        Sie jetzt und überholen Sie Ihre Konkurrenz!
                    </motion.p>
                    <motion.div
                        className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4"
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.4, duration: 0.5}}
                    >
                        <a href="#konfigurator" className={primaryButtonClasses}>
                            <Settings className="inline-block mr-2 h-5 w-5"/>
                            Website konfigurieren
                        </a>
                        <a href="#termin" className={secondaryButtonClasses}>
                            <PhoneCall className="inline-block mr-2 h-5 w-5"/>
                            Beratungstermin
                        </a>
                    </motion.div>
                </motion.div>

                <motion.div
                    className="bg-white dark:bg-gray-800 rounded-3xl shadow-2xl overflow-hidden transition-shadow duration-300 hover:shadow-3xl mb-24"
                    initial={{opacity: 0, scale: 0.95}}
                    animate={{opacity: 1, scale: 1}}
                    transition={{delay: 0.6, duration: 0.5}}
                >
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                        <div className="px-8 py-12 sm:px-12 lg:py-16 lg:pr-0 xl:py-20 xl:pl-16">
                            <div className="lg:self-center">
                                <h2 className="text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl">
                                    <span className="block">Starten Sie mit einer</span>
                                    <span
                                        className="block bg-clip-text text-transparent bg-gradient-to-r from-violet-500 to-fuchsia-500">individuellen Webpräsenz</span>
                                </h2>
                                <p className="mt-4 text-xl text-gray-500 dark:text-gray-300">
                                    Investieren Sie in Ihre Zukunft ab <span
                                    className="font-bold text-green-600 dark:text-green-400">€500 einmalig</span>*. Ihr
                                    maßgeschneidertes Angebot wird nach einer detaillierten Bedarfsanalyse erstellt.
                                </p>
                                <div className="mt-8 flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                                    <a href="#konfigurator" className={primaryButtonClasses}>
                                        Jetzt konfigurieren
                                        <ArrowRight className="inline-block ml-2 h-5 w-5"/>
                                    </a>
                                    <a href="#termin" className={secondaryButtonClasses}>
                                        Beratungstermin
                                        <ChevronRight className="inline-block ml-2 h-5 w-5"/>
                                    </a>
                                </div>
                                <p className="mt-6 text-sm text-gray-500 dark:text-gray-400">
                                    * Preise zzgl. MwSt. und Hosting-Kosten. Dies ist ein Ab-Preis, der einen groben
                                    Richtwert darstellt. Der endgültige Preis wird nach einer individuellen
                                    Bedarfsanalyse festgelegt.
                                </p>
                            </div>
                        </div>

                        <div className="relative -mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                            <img
                                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                                src="/api/placeholder/800/600"
                                alt="Beispiel einer maßgeschneiderten Website"
                            />
                        </div>
                    </div>
                </motion.div>

                <motion.div
                    className="mb-24"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 0.8, duration: 0.5}}
                >
                    <h3 className="text-4xl font-bold text-center text-gray-900 dark:text-white mb-16">
                        Wählen Sie das perfekte Hosting für Ihren Erfolg
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {plans.map((plan, index) => (
                            <motion.div
                                key={plan.name}
                                className={`rounded-2xl overflow-hidden transition-all duration-300 ${
                                    plan.recommended
                                        ? 'border-2 border-violet-500 dark:border-fuchsia-400 transform scale-105'
                                        : 'border border-gray-200 dark:border-gray-700'
                                } ${
                                    hoveredPlan === index
                                        ? 'transform scale-105 z-10 bg-white dark:bg-gray-800 shadow-2xl'
                                        : 'bg-white dark:bg-gray-900 shadow-lg'
                                }`}
                                onMouseEnter={() => setHoveredPlan(index)}
                                onMouseLeave={() => setHoveredPlan(null)}
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: 1 + index * 0.2, duration: 0.5}}
                            >
                                <div className="p-8">
                                    {plan.recommended && (
                                        <div
                                            className="flex items-center justify-center mb-4 bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-full py-1 px-3">
                                            <Star className="h-4 w-4 text-white mr-1"/>
                                            <span className="text-sm font-medium text-white">Bestseller</span>
                                        </div>
                                    )}
                                    <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">{plan.name}</h2>
                                    <p className="mb-6">
                                        <span
                                            className="text-4xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-violet-500 to-fuchsia-500">{plan.price}</span>
                                        <span
                                            className="text-base font-medium text-gray-500 dark:text-gray-400">/monat</span>
                                    </p>
                                    <ul className="mb-8 space-y-4">
                                        {plan.features.map((feature) => (
                                            <li key={feature} className="flex items-center">
                                                <Check className="flex-shrink-0 h-5 w-5 text-green-500 mr-3"/>
                                                <span className="text-gray-600 dark:text-gray-300">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    <a
                                        href="#"
                                        className={`block w-full text-center py-3 px-4 rounded-full transition-colors duration-300 ${
                                            plan.recommended
                                                ? 'bg-gradient-to-r from-violet-500 to-fuchsia-500 hover:from-violet-600 hover:to-fuchsia-600 text-white'
                                                : 'bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-800 dark:text-white'
                                        }`}
                                    >
                                        {plan.cta}
                                    </a>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>

                <motion.div
                    className="bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-2xl p-6 sm:p-8 md:p-12 mb-16 sm:mb-20 md:mb-24 transition-colors duration-300"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 1.6, duration: 0.5}}
                >
                    <h3 className="text-2xl sm:text-3xl font-semibold text-white mb-6 sm:mb-8">Ihr Weg zum digitalen
                        Erfolg:</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-10 md:gap-12">
                        <div className="flex items-start">
                            <div className="flex-shrink-0 bg-white bg-opacity-20 rounded-full p-3 sm:p-4">
                                <Settings className="h-6 sm:h-8 w-6 sm:w-8 text-white"/>
                            </div>
                            <div className="ml-4 sm:ml-6">
                                <h4 className="text-lg sm:text-xl font-medium text-white mb-2">Einfache
                                    Konfiguration</h4>
                                <p className="text-white text-opacity-80">
                                    Nutzen Sie unseren intuitiven Konfigurator für ein maßgeschneidertes Angebot. In nur
                                    wenigen Minuten erhalten Sie eine präzise Einschätzung Ihrer individuellen Lösung.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <div className="flex-shrink-0 bg-white bg-opacity-20 rounded-full p-3 sm:p-4">
                                <PhoneCall className="h-6 sm:h-8 w-6 sm:w-8 text-white"/>
                            </div>
                            <div className="ml-4 sm:ml-6">
                                <h4 className="text-lg sm:text-xl font-medium text-white mb-2">Persönliche
                                    Expertenberatung</h4>
                                <p className="text-white text-opacity-80">
                                    Profitieren Sie von unserer Erfahrung. In einem persönlichen Gespräch analysieren
                                    wir Ihre Bedürfnisse und entwickeln gemeinsam die optimale Strategie für Ihren
                                    Online-Erfolg.
                                </p>
                            </div>
                        </div>
                    </div>
                </motion.div>


                <motion.div
                    className="bg-red-50 dark:bg-red-900 rounded-2xl p-8 flex flex-col sm:flex-row items-center justify-between transition-colors duration-300"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 1.8, duration: 0.5}}
                >
                    <div className="flex items-center mb-6 sm:mb-0">
                        <Clock className="h-12 w-12 text-red-600 dark:text-red-400 mr-6"/>
                        <div>
                            <h4 className="text-2xl font-bold text-red-700 dark:text-red-300 mb-2">Exklusives
                                Zeitangebot</h4>
                            <p className="text-red-600 dark:text-red-200">
                                Sichern Sie sich jetzt Ihren Vorteil! Limitierte Plätze für individuelle Beratungen
                                verfügbar.
                            </p>
                        </div>
                    </div>
                    <a
                        href="#"
                        className={`${primaryButtonClasses} bg-red-600 hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-600`}
                    >
                        Jetzt Termin sichern
                    </a>
                </motion.div>

                <motion.div
                    className="mt-24 text-center"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 2, duration: 0.5}}
                >
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
                        Häufig gestellte Fragen
                    </h2>
                    <div className="max-w-3xl mx-auto">
                        {[
                            {
                                question: "Wie lange dauert die Erstellung meiner Website?",
                                answer: "Die Dauer variiert je nach Umfang und Komplexität. Typischerweise dauert es 2-4 Wochen von der Konzeption bis zum Launch."
                            },
                            {
                                question: "Kann ich meine Website später erweitern?",
                                answer: "Absolut! Unsere Lösungen sind skalierbar und wachsen mit Ihrem Unternehmen mit."
                            },
                            {
                                question: "Bieten Sie auch SEO-Optimierung an?",
                                answer: "Ja, wir integrieren grundlegende SEO-Praktiken in jede Website und bieten zusätzliche SEO-Services für eine verbesserte Sichtbarkeit."
                            }
                        ].map((faq, index) => (
                            <motion.div
                                key={index}
                                className="mb-8 last:mb-0 bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: 2.2 + index * 0.2, duration: 0.5}}
                            >
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">{faq.question}</h3>
                                <p className="text-gray-600 dark:text-gray-300">{faq.answer}</p>
                            </motion.div>
                        ))}
                    </div>
                </motion.div>

                <motion.div
                    className="mt-24 bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-3xl p-12 text-center text-white"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: 2.8, duration: 0.5}}
                >
                    <h2 className="text-4xl font-bold mb-6">Bereit, Ihre digitale Reise zu beginnen?</h2>
                    <p className="text-xl mb-8">Lassen Sie uns gemeinsam Ihre Vision in die Realität umsetzen.</p>
                    <a
                        href="#"
                        className="inline-block bg-white text-violet-600 font-bold py-4 px-8 rounded-full hover:bg-gray-100 transition-colors duration-300 shadow-lg hover:shadow-xl"
                    >
                        Jetzt starten
                    </a>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default PricingPage;