import React, { useState, useEffect, ReactNode } from 'react';
import { Sun, Moon, Menu, X, ArrowRight, Home, Banknote, ClipboardList, Mail, Linkedin, Github, Instagram, Twitter, MapPin, Phone } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import { LucideProps } from 'lucide-react';
import Cookies from 'js-cookie';
import './App.css';
import HomePage from './pages/Home';
import Steps from './pages/Steps';
import ExitIntentPopup from "./pages/ExitIntentPopup";
import Price from "./pages/Price";
import Impressum from "./pages/Impressum"; 
import TermsAndConditions from "./pages/Bedingungen";
import Datenschutz from "./pages/Datenschutz";
// Updated Tailwind CSS classes for an even more modern design
const buttonClasses = "px-6 py-3 rounded-full transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-lg hover:shadow-xl text-sm font-semibold";
const primaryButtonClasses = `${buttonClasses} bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white hover:from-violet-600 hover:to-fuchsia-600 focus:ring-violet-400`;
const secondaryButtonClasses = `${buttonClasses} bg-gray-100 text-gray-800 hover:bg-gray-200 focus:ring-gray-400 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700`;

interface NavItemProps {
    href: string;
    children: ReactNode;
    icon: React.FC<LucideProps>;
    isActive: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ href, children, icon: Icon, isActive }) => (
    <Link
        to={href}
        className={`group flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-300 ${
            isActive
                ? 'text-violet-500 bg-violet-100 dark:bg-violet-900 dark:text-violet-400'
                : 'text-gray-700 hover:text-violet-500 dark:text-gray-300 dark:hover:text-violet-400'
        }`}
    >
        <Icon className={`h-5 w-5 ${isActive ? 'text-violet-500 dark:text-violet-400' : 'text-gray-400 group-hover:text-violet-500 dark:text-gray-500 dark:group-hover:text-violet-400'}`} />
        <span className="font-medium">{children}</span>
    </Link>
);

const SocialIcon: React.FC<{ href: string; icon: React.FC<LucideProps>; color: string }> = ({ href, icon: Icon, color }) => (
    <motion.a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.1, y: -3 }}
        whileTap={{ scale: 0.9 }}
        className={`p-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 ${color}`}
    >
        <Icon className="h-5 w-5 text-white" />
    </motion.a>
);

const FooterLink: React.FC<{ href: string; children: ReactNode }> = ({ href, children }) => (
    <motion.li whileHover={{ x: 3 }} whileTap={{ scale: 0.95 }}>
        <Link to={href} className="hover:text-violet-300 transition-colors duration-300">{children}</Link>
    </motion.li>
);

const Footer: React.FC = () => (
    <footer className="bg-gradient-to-r from-violet-600 to-fuchsia-600 text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
                <div className="space-y-6">
                    <img src="/logo/ovotic-white.svg" alt="Ovotic Logo" className="h-16" />
                    <p className="text-sm leading-relaxed">
                        "Damit der erste Eindruck nicht der Letzte ist". Ovotic optimiert Ihre Online-Präsenz für einen unvergesslichen ersten Eindruck.
                    </p>
                </div>
                <div>
                    <h3 className="text-2xl font-bold mb-6">Schnelllinks</h3>
                    <ul className="space-y-4">
                        <FooterLink href="/datenschutz">Datenschutz</FooterLink>
                        <FooterLink href="/impressum">Impressum</FooterLink>
                        <FooterLink href="/agb">AGB</FooterLink>
                    </ul>
                </div>
                <div>
                    <h3 className="text-2xl font-bold mb-6">Kontakt</h3>
                    <ul className="space-y-4 text-sm">
                        <li className="flex items-center"><Mail className="mr-3 h-5 w-5" /> info@ovotic.com</li>
                        <li className="flex items-center"><Phone className="mr-3 h-5 w-5" /> +49 123 456789</li>
                        <li className="flex items-center"><MapPin className="mr-3 h-5 w-5" /> Musterstraße 123, 12345 Berlin</li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-2xl font-bold mb-6">Folgen Sie uns</h3>
                    <div className="flex space-x-4">
                        <SocialIcon href="https://linkedin.com" icon={Linkedin} color="bg-blue-600" />
                        <SocialIcon href="https://github.com" icon={Github} color="bg-gray-800" />
                        <SocialIcon href="https://instagram.com" icon={Instagram} color="bg-pink-600" />
                        <SocialIcon href="https://twitter.com" icon={Twitter} color="bg-blue-400" />
                    </div>
                </div>
            </div>
            <div className="mt-12 pt-8 border-t border-white/20 text-center text-sm">
                <p>&copy; {new Date().getFullYear()} Ovotic. Alle Rechte vorbehalten.</p>
            </div>
        </div>
    </footer>
);

const CookieBanner: React.FC<{ onAccept: () => void; onReject: () => void }> = ({onAccept, onReject}) => (
    <motion.div
        initial={{y: 100, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: 100, opacity: 0}}
        transition={{type: "spring", stiffness: 300, damping: 30}}
        className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 p-6 shadow-2xl z-50 rounded-t-3xl"
    >
        <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0">
            <div className="text-center sm:text-left max-w-2xl">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Datenschutzeinstellungen</h3>
                <p className="text-sm text-gray-700 dark:text-gray-300">
                    Wir verwenden Cookies, um Ihr Nutzungserlebnis zu verbessern. Sie können Ihre Einwilligung jederzeit anpassen.
                </p>
            </div>
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                <motion.button
                    whileHover={{scale: 1.05}}
                    whileTap={{scale: 0.95}}
                    onClick={onAccept}
                    className={primaryButtonClasses}
                >
                    Alle akzeptieren
                </motion.button>
                <motion.button
                    whileHover={{scale: 1.05}}
                    whileTap={{scale: 0.95}}
                    onClick={onReject}
                    className={secondaryButtonClasses}
                >
                    Nur notwendige Cookies
                </motion.button>
            </div>
        </div>
    </motion.div>
);

const App: React.FC = () => {
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = Cookies.get('darkMode');
        return savedMode ? savedMode === 'true' : true; // Default to dark mode
    });
    const [menuOpen, setMenuOpen] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState<boolean | null>(() => {
        const savedCookies = Cookies.get('cookiesAccepted');
        return savedCookies ? JSON.parse(savedCookies) : null;
    });
    const [scrollPosition, setScrollPosition] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => setScrollPosition(window.scrollY);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        document.documentElement.classList.toggle('dark', darkMode);
        Cookies.set('darkMode', darkMode.toString(), { expires: 365, sameSite: 'None', secure: true });
    }, [darkMode]);

    const toggleDarkMode = () => setDarkMode(prev => !prev);
    const toggleMenu = () => setMenuOpen(prev => !prev);

    const handleCookieAccept = () => {
        setCookiesAccepted(true);
        Cookies.set('cookiesAccepted', 'true', { expires: 365, sameSite: 'None', secure: true });
    };
    const handleCookieReject = () => {
        setCookiesAccepted(false);
        Cookies.set('cookiesAccepted', 'false', { expires: 365, sameSite: 'None', secure: true });
    };

    return (
        <div className={`min-h-screen flex flex-col ${darkMode ? 'dark' : ''} bg-gray-50 dark:bg-gray-900 transition-all duration-500 font-sans`}>
            {/* Header */}
            <header className={`fixed w-full z-10 bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg shadow-lg transition-all duration-500 ${scrollPosition > 50 ? 'py-2' : 'py-4'}`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                    <div className="flex items-center">
                        <img className="h-10 w-auto" src={darkMode ? "/logo/ovotic-white.svg" : "/logo/ovotic-dark.svg"} alt="Ovotic Logo"/>
                        <nav className="hidden md:flex space-x-6 ml-10">
                            <NavItem href="/" icon={Home} isActive={location.pathname === '/'}>Home</NavItem>
                            <NavItem href="/ablauf" icon={ClipboardList} isActive={location.pathname === '/ablauf'}>Ablauf</NavItem>
                            <NavItem href="/preise" icon={Banknote} isActive={location.pathname === '/preise'}>Preise</NavItem>
                        </nav>
                    </div>
                    <div className="flex items-center space-x-6">
                        <motion.button
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                            className={`${primaryButtonClasses} hidden sm:inline-flex`}
                        >
                            Jetzt loslegen <ArrowRight className="ml-2 h-5 w-5 inline"/>
                        </motion.button>
                        <motion.button
                            onClick={toggleDarkMode}
                            className="p-2 text-gray-500 hover:text-violet-500 transition-all duration-300"
                            whileHover={{scale: 1.1, rotate: 180}}
                            whileTap={{scale: 0.9}}
                        >
                            {darkMode ? <Sun className="h-6 w-6"/> : <Moon className="h-6 w-6"/>}
                        </motion.button>
                        <motion.button
                            onClick={toggleMenu}
                            className="md:hidden p-2 text-gray-500 hover:text-violet-500"
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.9}}
                        >
                            {menuOpen ? <X className="h-6 w-6"/> : <Menu className="h-6 w-6"/>}
                        </motion.button>
                    </div>
                </div>
            </header>

            {/* Mobile Menu */}
            <AnimatePresence>
                {menuOpen && (
                    <motion.div
                        className="fixed inset-0 z-20 bg-black bg-opacity-50 backdrop-blur-sm md:hidden"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={toggleMenu}
                    >
                        <motion.div
                            className="absolute right-0 h-full w-2/3 max-w-md bg-white dark:bg-gray-800 shadow-2xl p-6"
                            initial={{ x: '100%' }}
                            animate={{ x: 0 }}
                            exit={{ x: '100%' }}
                            transition={{ type: "spring", stiffness: 300, damping: 30 }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex items-center justify-between mb-6">
                                <p className="text-2xl font-semibold text-gray-900 dark:text-white">Navigation</p>
                                <button
                                    className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition"
                                    onClick={toggleMenu}
                                >
                                    <X className="h-6 w-6 text-gray-600 dark:text-gray-400" />
                                </button>
                            </div>
                            <nav className="flex flex-col space-y-4">
                                <NavItem href="/" icon={Home} isActive={location.pathname === '/'}>Home</NavItem>
                                <NavItem href="/ablauf" icon={ClipboardList} isActive={location.pathname === '/ablauf'}>Ablauf</NavItem>
                                <NavItem href="/preise" icon={Banknote} isActive={location.pathname === '/preise'}>Preise</NavItem>
                            </nav>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className={`${primaryButtonClasses} w-full mt-8`}
                            >
                                Jetzt loslegen <ArrowRight className="ml-2 h-5 w-5 inline" />
                            </motion.button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Main content */}
            <main className="flex-1 pt-24 px-4 sm:px-6 lg:px-8 font-nunito">
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/ablauf" element={<Steps/>}/>
                    
                    <Route path="/preise" element={<Price/>}/>
                    <Route path="/impressum" element={<Impressum/>}/>
                    <Route path="/agb" element={<TermsAndConditions/>}/>
                    <Route path="/datenschutz" element={<Datenschutz/>}/>
                    
                </Routes>
            </main>
            <ExitIntentPopup/>

            {/* Footer */}
            <Footer/>

            {/* Cookie Banner */}
            <AnimatePresence>
                {cookiesAccepted === null && (
                    <CookieBanner onAccept={handleCookieAccept} onReject={handleCookieReject}/>
                )}
            </AnimatePresence>
        </div>
    );
};

export default App;