import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { X, Clock, ArrowRight, CheckCircle, DollarSign, Zap, Lock, Gift } from 'lucide-react';
import Cookies from 'js-cookie';

const ExitIntentPopup: React.FC = () => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showCountdown, setShowCountdown] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(60 * 60); // 1 hour in seconds
    const [step, setStep] = useState<number>(1);
    const [userInactive, setUserInactive] = useState<boolean>(false);
    const [popupClosed, setPopupClosed] = useState<boolean>(false);
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const controls = useAnimation();
    const lastMousePosition = useRef({ x: 0, y: 0 });

    useEffect(() => {
        const popupShown = Cookies.get('exitIntentPopupShown');
        const savedTimeLeft = Cookies.get('exitIntentTimeLeft');
        if (savedTimeLeft) {
            const parsedTimeLeft = parseInt(savedTimeLeft);
            setTimeLeft(parsedTimeLeft);
            if (parsedTimeLeft <= 0) {
                setIsExpired(true);
            } else {
                setShowCountdown(true);
            }
        } else if (popupShown === 'true') {
            // Das Popup wurde bereits gezeigt
            setShowCountdown(true);
            setTimeLeft(60 * 60); // 1 Stunde initial setzen
        }
    }, []);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showCountdown && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    const newTime = prevTime > 0 ? prevTime - 1 : 0;
                    Cookies.set('exitIntentTimeLeft', newTime.toString(), { expires: 1 });
                    if (newTime === 0) {
                        setIsExpired(true);
                    }
                    return newTime;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [showCountdown, timeLeft]);






    useEffect(() => {

        const handleMouseMove = (event: MouseEvent) => {
            const { clientX, clientY } = event;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            // Trigger popup if mouse is near the top edge or the left/right edges
            if (clientY <= 0 || clientX <= 0 || clientX >= windowWidth - 1) {
                setShowPopup(true);
            }

            lastMousePosition.current = { x: clientX, y: clientY };
        };

        const handleMouseLeave = (event: MouseEvent) => {
            if (event.clientY <= 0) {
                setShowPopup(true);
            }
        };

        const handleVisibilityChange = () => {
            if (!document.hidden) {
            }
        };

        document.addEventListener('mouseleave', handleMouseLeave);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('mouseleave', handleMouseLeave);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, );

    useEffect(() => {
        if (userInactive) {
            setShowPopup(true);
        }
    }, [userInactive]);

    useEffect(() => {
        if (showPopup) {
            setShowCountdown(true);
        }
    }, [showPopup]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showCountdown && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    const newTime = prevTime > 0 ? prevTime - 1 : 0;
                    Cookies.set('exitIntentTimeLeft', newTime.toString(), { expires: 1 });
                    if (newTime % 60 === 0 && newTime > 0) {
                        controls.start({
                            scale: [1, 1.05, 1],
                            rotate: [0, 1, -1, 0],
                            transition: { duration: 0.5 }
                        });
                    }
                    if (newTime === 0) {
                        setIsExpired(true);
                    }
                    return newTime;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [showCountdown, timeLeft, controls]);

    const handleClose = () => {
        setShowPopup(false);
        setPopupClosed(true);
        Cookies.set('exitIntentPopupShown', 'true', { expires: 365, sameSite: 'None', secure: true });    
    };

    const nextStep = () => setStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep));

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const CountdownCircle = ({ timeLeft }: { timeLeft: number }) => {
        const percentage = (timeLeft / (60 * 60)) * 100;
        return (
            <div className="relative w-16 h-16 sm:w-20 sm:h-20">
                <svg className="w-full h-full" viewBox="0 0 100 100">
                    <circle
                        className="text-gray-200 dark:text-gray-700 stroke-current"
                        strokeWidth="8"
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                    />
                    <circle
                        className="text-blue-600 dark:text-blue-400 progress-ring__circle stroke-current"
                        strokeWidth="8"
                        strokeLinecap="round"
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        strokeDasharray="251.2"
                        strokeDashoffset={251.2 - (percentage / 100) * 251.2}
                        transform="rotate(-90 50 50)"
                    />
                </svg>
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <span className="text-xs sm:text-sm font-bold">
                        {isExpired ? 'Zu Spät' : formatTime(timeLeft)}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <AnimatePresence>
            {showPopup && !popupClosed && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
                >
                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        key="popup"
                        exit={{ y: 50, opacity: 0 }}
                        className="bg-white dark:bg-gray-800 rounded-3xl overflow-hidden shadow-2xl max-w-md w-full"
                    >
                        <div className="relative p-6 sm:p-8">
                            <button
                                onClick={handleClose}
                                className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors"
                            >
                                <X className="h-6 w-6" />
                            </button>

                            {step === 1 && (
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    className="text-center"
                                >
                                    <Gift className="h-16 w-16 mx-auto mb-4 text-blue-500 dark:text-blue-400" />
                                    <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-blue-600 dark:text-blue-400">Exklusives Angebot!</h2>
                                    <p className="text-lg sm:text-xl mb-6 dark:text-gray-200">
                                        Sparen Sie jetzt 100€ bei Ihrer ersten professionellen Website!
                                    </p>
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={nextStep}
                                        className="bg-blue-500 text-white font-bold py-3 sm:py-4 px-6 sm:px-8 rounded-full text-base sm:text-lg shadow-lg hover:bg-blue-600 transition-colors flex items-center justify-center w-full"
                                    >
                                        Ich möchte sparen! <ArrowRight className="ml-2" />
                                    </motion.button>
                                </motion.div>
                            )}

                            {step === 2 && (
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    className="text-center"
                                >
                                    <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-green-600 dark:text-green-400">Großartige Entscheidung!</h2>
                                    <div className="bg-green-100 dark:bg-green-800 p-4 sm:p-6 rounded-2xl mb-6 transform rotate-2 hover:rotate-0 transition-transform duration-300">
                                        <p className="text-2xl sm:text-3xl font-semibold text-green-800 dark:text-green-200">Sparen Sie 100€</p>
                                        <p className="text-lg sm:text-xl text-green-700 dark:text-green-300">auf Ihre erste professionelle Website</p>
                                    </div>
                                    <div className="flex items-center justify-center text-xl sm:text-2xl font-bold text-red-500 mb-6">
                                        <Clock className="mr-2" />
                                        <span>Angebot endet in: {formatTime(timeLeft)}</span>
                                    </div>
                                    <ul className="text-left mb-6 space-y-3">
                                        {['Professionelles Design', 'Mobilfreundlich', 'SEO-Optimiert'].map((feature, index) => (
                                            <motion.li
                                                key={index}
                                                initial={{ opacity: 0, x: -20 }}
                                                animate={{ opacity: 1, x: 0 }}
                                                transition={{ delay: index * 0.1 }}
                                                className="flex items-center dark:text-white"
                                            >
                                                <CheckCircle className="text-green-500 mr-2 flex-shrink-0" />
                                                <span>{feature}</span>
                                            </motion.li>
                                        ))}
                                    </ul>
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={nextStep}
                                        className="bg-green-500 text-white font-bold py-3 sm:py-4 px-6 sm:px-8 rounded-full text-base sm:text-lg shadow-lg hover:bg-green-600 transition-colors flex items-center justify-center w-full"
                                    >
                                        Ja, ich will 100€ sparen! <ArrowRight className="ml-2" />
                                    </motion.button>
                                </motion.div>
                            )}

                            {step === 3 && (
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    className="text-center"
                                >
                                    <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-purple-600 dark:text-purple-400">Ihr exklusiver Rabattcode</h2>
                                    <motion.div
                                        initial={{ scale: 0.9 }}
                                        animate={{ scale: 1 }}
                                        transition={{ yoyo: Infinity, duration: 2 }}
                                        className="bg-purple-100 dark:bg-purple-800 p-4 sm:p-6 rounded-2xl mb-6 transform hover:rotate-2 transition-transform duration-300"
                                    >
                                        <p className="text-2xl sm:text-3xl font-mono font-semibold text-purple-800 dark:text-purple-200">FIRSTSITE100</p>
                                    </motion.div>
                                    <p className="text-lg sm:text-xl mb-8 dark:text-gray-200">
                                        Verwenden Sie diesen Code beim Checkout, um 100€ zu sparen!
                                    </p>
                                    <div className="grid grid-cols-3 gap-4 sm:gap-6 mb-8">
                                        {[
                                            { Icon: DollarSign, text: '100€ Rabatt', color: 'text-yellow-500' },
                                            { Icon: Zap, text: 'Sofort nutzbar', color: 'text-blue-500' },
                                            { Icon: Lock, text: 'Sicher & Garantiert', color: 'text-green-500' }
                                        ].map(({ Icon, text, color }, index) => (
                                            <motion.div
                                                key={index}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ delay: index * 0.1 }}
                                                className="text-center"
                                            >
                                                <Icon className={`h-8 w-8 sm:h-12 sm:w-12 mx-auto ${color}`} />
                                                <p className="text-xs sm:text-sm mt-2 dark:text-white font-medium">{text}</p>
                                            </motion.div>
                                        ))}
                                    </div>
                                    <motion.a
                                        href="#" // Add the actual link here
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        className="bg-purple-500 text-white font-bold py-3 sm:py-4 px-6 sm:px-8 rounded-full text-base sm:text-xl shadow-lg hover:bg-purple-600 transition-colors flex items-center justify-center w-full"
                                    >
                                        Jetzt meine Website erstellen
                                    </motion.a>
                                </motion.div>
                            )}
                        </div>
                    </motion.div>
                </motion.div>
            )}
            {showCountdown && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    key="countdown"
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ delay: 0.5 }}
                    className="fixed bottom-4 right-4 sm:bottom-6 sm:right-6 z-40"
                >
                    <motion.div
                        animate={controls}
                        whileHover={{ scale: 1.05 }}
                        className="bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-3 sm:p-4 rounded-full shadow-lg flex items-center space-x-3 sm:space-x-4"
                    >
                        <CountdownCircle timeLeft={timeLeft} />
                        <div>
                            <p className="text-xs sm:text-sm font-semibold">
                                {isExpired ? "Angebot abgelaufen" : "Limitiertes Angebot"}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                {isExpired ? "Neue Angebote bald" : "Code: FIRSTSITE100"}
                            </p>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default ExitIntentPopup;