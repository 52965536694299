import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sun, Moon, Mail, Phone, FileText, Building, User, ChevronDown } from 'lucide-react';

const Impressum = () => {
    const [isDarkMode] = useState(false);
    const [expandedSection, setExpandedSection] = useState<number | null>(null);

    const impressumData = {
        companyName: "Muster GmbH",
        owner: "Max Mustermann",
        address: "Musterstraße 123, 12345 Musterstadt",
        email: "info@muster-gmbh.de",
        phone: "+49 123 456789",
        vatId: "DE123456789",
        commercialRegister: "HRB 12345, Amtsgericht Musterstadt",
    };

    const sections = [
        {
            title: "Angaben gemäß § 5 TMG",
            icon: <Building size={24} />,
            content: (
                <>
                    <p>{impressumData.companyName}</p>
                    <p>{impressumData.owner}</p>
                    <p>{impressumData.address}</p>
                </>
            )
        },
        {
            title: "Kontakt",
            icon: <Mail size={24} />,
            content: (
                <>
                    <p>Telefon: {impressumData.phone}</p>
                    <p>E-Mail: {impressumData.email}</p>
                </>
            )
        },
        {
            title: "Umsatzsteuer-ID",
            icon: <FileText size={24} />,
            content: (
                <>
                    <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</p>
                    <p>{impressumData.vatId}</p>
                </>
            )
        },
        {
            title: "Handelsregister",
            icon: <FileText size={24} />,
            content: <p>{impressumData.commercialRegister}</p>
        },
        {
            title: "Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV",
            icon: <User size={24} />,
            content: (
                <>
                    <p>{impressumData.owner}</p>
                    <p>{impressumData.address}</p>
                </>
            )
        },
    ];

    return (
        <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
            <div className="min-h-screen transition-colors duration-500 py-12 px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="max-w-4xl mx-auto bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden"
                >
                    <div className="p-8">
                        <div className="flex justify-between items-center mb-8">
                            <motion.h1
                                initial={{ x: -20 }}
                                animate={{ x: 0 }}
                                className="text-4xl font-bold text-gray-900 dark:text-white flex items-center gap-3"
                            >
                                <Building className="text-indigo-600 dark:text-indigo-400" size={40} />
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-purple-600 dark:from-indigo-400 dark:to-purple-500">
                  Impressum
                </span>
                            </motion.h1>
                        </div>


                        <div className="space-y-6">
                            {sections.map((section, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                    className="border-b border-indigo-200 dark:border-indigo-700 pb-4"
                                >
                                    <button
                                        onClick={() => setExpandedSection(expandedSection === index ? null : index)}
                                        className="w-full text-left"
                                    >
                                        <div className="flex items-center justify-between">
                                            <h2 className="text-xl font-semibold text-indigo-700 dark:text-indigo-300 flex items-center gap-3">
                        <span className="p-2 bg-indigo-100 dark:bg-indigo-800 rounded-full">
                          {section.icon}
                        </span>
                                                {section.title}
                                            </h2>
                                            <ChevronDown
                                                className={`text-indigo-500 dark:text-indigo-400 transform transition-transform duration-300 ${
                                                    expandedSection === index ? 'rotate-180' : ''
                                                }`}
                                            />
                                        </div>
                                    </button>
                                    <AnimatePresence>
                                        {expandedSection === index && (
                                            <motion.div
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: 'auto' }}
                                                exit={{ opacity: 0, height: 0 }}
                                                transition={{ duration: 0.3 }}
                                                className="mt-4 text-gray-700 dark:text-gray-300 pl-12"
                                            >
                                                {section.content}
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </motion.div>
                            ))}
                        </div>

                        <motion.div
                            className="mt-12 bg-gradient-to-r from-indigo-50 to-purple-50 dark:from-indigo-900/20 dark:to-purple-900/20 p-8 rounded-2xl shadow-inner"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.5 }}
                        >
                            <h2 className="text-2xl font-semibold mb-4 text-indigo-700 dark:text-indigo-300">Haben Sie Fragen zu unserem Impressum?</h2>
                            <p className="mb-6 text-indigo-600 dark:text-indigo-200">
                                Wenn Sie Fragen oder Anmerkungen zu unserem Impressum haben, zögern Sie bitte nicht, uns zu kontaktieren.
                            </p>
                            <motion.a
                                whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(79, 70, 229, 0.5)" }}
                                whileTap={{ scale: 0.95 }}
                                href={`mailto:${impressumData.email}`}
                                className="inline-block px-8 py-4 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-full font-semibold hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 shadow-lg"
                            >
                                Kontakt aufnehmen
                            </motion.a>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Impressum;