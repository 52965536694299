import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sun, Moon, Shield, Eye, Lock, Server, Trash2, MessageCircle, ChevronDown } from 'lucide-react';





const PrivacyPolicy = () => {
    const [isDarkMode] = useState(false);
    const [expandedSection, setExpandedSection] = useState<number | null>(null);

    const sections = [
        { title: "Datenschutz auf einen Blick", icon: <Eye size={24} />, content: "Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Ihre Privatsphäre ist für uns ein wichtiges Anliegen." },
        { title: "Datenerfassung auf unserer Website", icon: <Server size={24} />, content: "Wir erheben und verarbeiten Ihre Daten ausschließlich im Rahmen der Bestimmungen der EU-Datenschutzgrundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG)." },
        { title: "Datenschutz", icon: <Lock size={24} />, content: "Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung." },
        { title: "Datenverarbeitung auf dieser Website", icon: <Server size={24} />, content: "Die Verarbeitung von Daten auf dieser Website erfolgt durch den Websitebetreiber. Die Kontaktdaten können Sie dem Impressum dieser Website entnehmen." },
        { title: "Ihre Rechte", icon: <MessageCircle size={24} />, content: "Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen." },
        { title: "Widerruf Ihrer Einwilligung zur Datenverarbeitung", icon: <Trash2 size={24} />, content: "Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns." },
    ];

    return (
        <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
            <div className=" min-h-screen transition-colors duration-500 py-12 px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="max-w-4xl mx-auto bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden"
                >
                    <div className="p-8">
                        <div className="flex justify-between items-center mb-8">
                            <motion.h1
                                initial={{x: -20}}
                                animate={{x: 0}}
                                className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 dark:text-white flex items-center gap-3"
                            >
                                <Shield className="text-teal-600 dark:text-teal-400" size={40}/>
                                <span
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-blue-600 dark:from-teal-400 dark:to-blue-500">
        Datenschutzerklärung
    </span>
                            </motion.h1>
                        </div>


                        <div className="space-y-6">
                            {sections.map((section, index) => (
                                <motion.div
                                    key={index}
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: index * 0.1 }}
                                    className="border-b border-teal-200 dark:border-teal-700 pb-4"
                                >
                                    <button
                                        onClick={() => setExpandedSection(expandedSection === index ? null : index)}
                                        className="w-full text-left"
                                    >
                                        <div className="flex items-center justify-between">
                                            <h2 className="text-xl font-semibold text-teal-700 dark:text-teal-300 flex items-center gap-3">
                        <span className="p-2 bg-teal-100 dark:bg-teal-800 rounded-full">
                          {section.icon}
                        </span>
                                                {section.title}
                                            </h2>
                                            <ChevronDown
                                                className={`text-teal-500 dark:text-teal-400 transform transition-transform duration-300 ${
                                                    expandedSection === index ? 'rotate-180' : ''
                                                }`}
                                            />
                                        </div>
                                    </button>
                                    <AnimatePresence>
                                        {expandedSection === index && (
                                            <motion.div
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: 'auto' }}
                                                exit={{ opacity: 0, height: 0 }}
                                                transition={{ duration: 0.3 }}
                                                className="mt-4 text-gray-700 dark:text-gray-300 pl-12"
                                            >
                                                <p>{section.content}</p>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </motion.div>
                            ))}
                        </div>

                        <motion.div
                            className="mt-12 bg-gradient-to-r from-teal-50 to-blue-50 dark:from-teal-900/20 dark:to-blue-900/20 p-8 rounded-2xl shadow-inner"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.5 }}
                        >
                            <h2 className="text-2xl font-semibold mb-4 text-teal-700 dark:text-teal-300">Haben Sie Fragen zum Datenschutz?</h2>
                            <p className="mb-6 text-teal-600 dark:text-teal-200">
                                Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation.
                            </p>
                            <motion.a
                                whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(20, 184, 166, 0.5)" }}
                                whileTap={{ scale: 0.95 }}
                                href="mailto:datenschutz@example.com"
                                className="inline-block px-8 py-4 bg-gradient-to-r from-teal-500 to-blue-600 text-white rounded-full font-semibold hover:from-teal-600 hover:to-blue-700 transition-all duration-300 shadow-lg"
                            >
                                Kontakt aufnehmen
                            </motion.a>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;