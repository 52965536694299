import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {Code, Zap, Star, Cpu, Globe, Rocket, ArrowRight, CheckCircle, Moon, Sun } from 'lucide-react';
import { LucideIcon } from 'lucide-react';
import { TrendingUp, Search, Users, BarChart2, ThumbsUp } from 'lucide-react';
const LandingPage = () => {
    const [theme, setTheme] = useState('light');
    

    return (
        <div className={`min-h-screen ${theme === 'dark' ? 'dark' : ''}`}>
            <div className="text-gray-900 dark:text-gray-100 transition-all duration-500">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
                    <HeroSection />
                    <GrowthSection />
                    <FeaturesSection />
                    <TestimonialsSection />
                    <CTASection />
                </div>
            </div>
        </div>
    );
};

const HeroSection = () => (
    <motion.section
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.8}}
        className="text-center mb-32 relative overflow-hidden px-4 sm:px-6 lg:px-8"
    >
        <motion.div
            className="absolute inset-0 z-0"
            animate={{}}
            transition={{duration: 10, repeat: Infinity, repeatType: 'reverse'}}
        />
        <motion.span
            className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600 dark:from-blue-400 dark:via-purple-400 dark:to-pink-400"
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
        >
            Digitale
        </motion.span>
        <br/>
        <motion.span
            className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-600 dark:from-pink-400 dark:via-purple-400 dark:to-blue-400"
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8, delay: 0.2}}
        >
            Meisterwerke
        </motion.span>

        <motion.p
            className="text-2xl mt-10 sm:text-3xl lg:text-4xl mb-12 text-gray-700 dark:text-gray-300 font-light italic"
            variants={{
                visible: {opacity: 1, y: 0},
                hidden: {opacity: 0, y: 20}
            }}
        >
            "Wo andere Websites bauen, komponieren wir digitale Symphonien."
        </motion.p>
        <motion.p
            className="max-w-3xl mx-auto text-lg sm:text-xl lg:text-2xl text-gray-600 dark:text-gray-400 mb-12"
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{delay: 0.5, duration: 0.5}}
        >
            Ovotic kreiert einzigartige Online-Erlebnisse, die Ihre Konkurrenz in den Schatten stellen und Ihre
            Zielgruppe fesseln.
        </motion.p>
        <motion.div
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{delay: 0.7, duration: 0.5}}
            className="mt-8 flex justify-center mb-10"
        >
            <div
                className="backdrop-blur-sm bg-white/80 dark:bg-gray-800/80 px-6 py-4 rounded-2xl shadow-lg border border-gray-100 dark:border-gray-700">
                <div className="flex items-center justify-center space-x-1">
                    {/* Full stars */}
                    {[...Array(4)].map((_, i) => (
                        <Star key={i} className="w-6 h-6 fill-[#00B67A] text-[#00B67A]"/>
                    ))}

                    {/* 70% filled fifth star */}
                    <div className="relative w-6 h-6">
                        <Star className="w-6 h-6 fill-none text-[#00B67A]"/>
                        <div className="absolute top-0 left-0 h-full overflow-hidden" style={{width: '70%'}}>
                            <Star className="w-6 h-6 fill-[#00B67A] text-[#00B67A]"/>
                        </div>
                    </div>
                </div>

                <div className="mt-2 flex items-center justify-center space-x-2">
                    <span className="text-[#00B67A] font-semibold">Trustpilot</span>
                </div>

                <div className="mt-2 text-center">
                    <div className="text-sm font-medium text-gray-700 dark:text-gray-200">
                        4.7 / 5 Exzellent
                    </div>
                    <div className="text-sm font-medium text-gray-700 dark:text-gray-200">
                        <b>Exzellent</b>
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                        Basierend auf <span className="font-medium">128</span> Bewertungen
                    </div>
                </div>
            </div>
        </motion.div>
        <motion.a
            href="#kontakt"
            whileHover={{scale: 1.05}}
            whileTap={{scale: 0.95}}
            className="inline-flex items-center bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-5 px-10 rounded-full transition-all transform hover:shadow-xl text-lg sm:text-xl relative overflow-hidden group"
        >
            <span className="relative z-10">Ihr Projekt starten</span>
            <motion.div
                className="absolute inset-0 bg-white opacity-0 group-hover:opacity-20 transition-opacity duration-300"
                initial={false}
                animate={{scale: [1, 1.5], opacity: [0.5, 0]}}
                transition={{duration: 1, repeat: Infinity}}
            />
            <ArrowRight className="w-6 h-6 ml-2 relative z-10"/>
        </motion.a>


    </motion.section>
)
const GrowthSection = () => {
    const growthPoints = [
        {
            icon: <TrendingUp/>,
            title: "300% mehr Conversions",
            description: "Verwandeln Sie Besucher in zahlende Kunden",
        },
        {
            icon: <Zap/>,
            title: "70% schnellere Ladezeiten",
            description: "Begeistern Sie Ihre Kunden mit Blitzgeschwindigkeit",
        },
        {icon: <Search/>, title: "Top 10 SEO-Ranking", description: "Werden Sie in Ihrer Branche unübersehbar"},
        {icon: <Users/>, title: "50% mehr Leads", description: "Expandieren Sie Ihr Geschäft exponentiell"},
        {
            icon: <BarChart2/>,
            title: "60% weniger Absprünge",
            description: "Fesseln Sie Ihre Besucher vom ersten Moment an",
        },
        {
            icon: <ThumbsUp/>,
            title: "40% höhere Kundenzufriedenheit",
            description: "Schaffen Sie begeisterte Markenbotschafter",
        },
    ];

    return (
        <motion.section
            initial={{opacity: 0, y: 50}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
            className="bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50 dark:from-blue-900 dark:via-purple-900 dark:to-pink-900 p-6 sm:p-12 rounded-3xl shadow-2xl mb-32"
        >
            <h2 className="text-4xl sm:text-5xl lg:text-6xl font-black mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400">
                Ihr Wachstum kennt keine Grenzen
            </h2>
            <p className="text-lg sm:text-xl lg:text-2xl text-gray-700 dark:text-gray-300 mb-12 text-center max-w-3xl mx-auto">
                Stellen Sie sich vor, Ihre Website wäre nicht nur ein digitales Schaufenster, sondern Ihr
                leistungsstärkster Verkäufer - 24/7 im Einsatz.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
                {growthPoints.map((point, index) => (
                    <GrowthCard key={index} {...point} />
                ))}
            </div>
            <motion.div
                className="mt-16 text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
            >
                <p className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-6">
                    Bereit, Ihr volles Potenzial zu entfesseln?
                </p>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-4 px-8 rounded-full text-xl shadow-lg hover:shadow-xl transition duration-300"
                >
                    Jetzt durchstarten
                </motion.button>
            </motion.div>
        </motion.section>
    );
};

interface GrowthCardProps {
    icon: React.ReactElement<LucideIcon>;
    title: string;
    description: string;
}

const GrowthCard: React.FC<GrowthCardProps> = ({ icon, title, description }) => (
    <motion.div
        className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
        whileHover={{ y: -5, boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)" }}
    >
        <motion.div
            className="text-blue-500 dark:text-blue-400 mb-4"
        >
            {React.cloneElement(icon, { })} {/* Größenanpassung */}
        </motion.div>
        <h3 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-2">{title}</h3>
        <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </motion.div>
);


interface GrowthPointProps {
    emoji: string;
    text: string;
}

const GrowthPoint: React.FC<GrowthPointProps> = ({ emoji, text }) => (
    <motion.div
        className="flex items-center space-x-4 bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md hover:shadow-xl transition-all duration-300"
        whileHover={{ scale: 1.03, boxShadow: "0 0 25px rgba(59, 130, 246, 0.3)" }}
        transition={{ type: "spring", stiffness: 300 }}
    >
        <span className="text-4xl">{emoji}</span>
        <p className="text-xl text-gray-700 dark:text-gray-300 font-medium">{text}</p>
    </motion.div>
);

const FeaturesSection = () => {
    const features = [
        {
            icon: <Code />,
            title: "100% Eigenentwicklung",
            description: "Jede Zeile Code ist ein Kunstwerk, speziell für Sie erschaffen und optimiert."
        },
        {
            icon: <Zap />,
            title: "Blitzschnelle Performance",
            description: "Unsere Websites laden schneller als ein Augenzwinkern, für maximale User-Retention."
        },
        {
            icon: <Star />,
            title: "Maßgeschneiderte Lösungen",
            description: "Individuelle Funktionen, die perfekt zu Ihrem Unternehmen passen und Ihre Unique Selling Points hervorheben."
        },
        {
            icon: <Cpu />,
            title: "KI-gesteuertes CMS",
            description: "Unser innovatives Content-Management-System lernt mit und optimiert Ihre Inhalte automatisch."
        },
        {
            icon: <Globe />,
            title: "SEO-Optimierung 2.0",
            description: "Unsere fortschrittlichen SEO-Techniken katapultieren Sie an die Spitze der Suchergebnisse."
        },
        {
            icon: <Rocket />,
            title: "Zukunftssichere Technologie",
            description: "Wir setzen auf Quantum-Computing-ready Architekturen für langfristigen Erfolg."
        }
    ];

    return (
        <motion.section
            initial={{opacity: 0, y: 50}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
            className="mb-32"
        >
            <h2 className="text-5xl sm:text-6xl lg:text-7xl font-black mb-16 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400">
                Unsere Expertise
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {features.map((feature, index) => (
                    <FeatureCard key={index} {...feature} />
                ))}
            </div>
        </motion.section>
    );
};


interface FeatureCardProps {
    icon: React.ReactElement<LucideIcon>;
    title: string;
    description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description }) => (
    <motion.div
        className="bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-lg dark:shadow-xl border border-gray-200 dark:border-gray-700 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-300 group"
        whileHover={{ scale: 1.05, boxShadow: "0 0 25px rgba(59, 130, 246, 0.5)" }}
        transition={{ type: "spring", stiffness: 300 }}
    >
        <motion.div
            className="flex items-center justify-center mb-6"
            whileHover={{ rotate: 360 }}
            transition={{ duration: 0.5 }}
        >
            {React.cloneElement(icon, {
                className: "w-16 h-16 text-blue-500 dark:text-blue-400 group-hover:text-purple-500 dark:group-hover:text-purple-400 transition-colors duration-300"
            } as unknown as React.ReactElement<any>)}
        </motion.div>
        <h3 className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-gray-100 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-300">{title}</h3>
        <p className="text-center text-gray-600 dark:text-gray-300 text-lg leading-relaxed group-hover:text-gray-800 dark:group-hover:text-gray-100 transition-colors duration-300">{description}</p>
    </motion.div>
);

const TestimonialsSection = () => {
    const testimonials = [
        {
            name: "Maria Schmidt",
            company: "TechStart GmbH",
            quote: "OVOTIC hat unsere Online-Präsenz komplett revolutioniert. Unser Umsatz ist um unglaubliche 300% gestiegen!"
        },
        {
            name: "Thomas Müller",
            company: "Green Solutions AG",
            quote: "Die Zusammenarbeit war phänomenal. Das Team verstand unsere Vision und setzte sie mit Perfektion und Innovation um."
        },
        {
            name: "Laura Weber",
            company: "Fashion Forward",
            quote: "Unsere neue Website ist nicht nur atemberaubend schön, sondern auch unfassbar benutzerfreundlich und performant."
        }
    ];

    return (
        <motion.section
            initial={{opacity: 0, y: 50}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
            className="mb-32"
        >
            <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-7xl font-black mb-16 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400">
                Erfolgsgeschichten
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
                {testimonials.map((testimonial, index) => (
                    <TestimonialCard key={index} {...testimonial} />
                ))}
            </div>
        </motion.section>
    );
};

interface TestimonialCardProps {
    name: string;
    company: string;
    quote: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({name, company, quote}) => (
    <motion.div
        className="bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-lg dark:shadow-xl border border-gray-200 dark:border-gray-700 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-300"
        whileHover={{ scale: 1.05, boxShadow: "0 0 25px rgba(59, 130, 246, 0.5)" }}
        transition={{ type: "spring", stiffness: 300 }}
    >
        <p className="text-gray-600 dark:text-gray-300 text-lg mb-6 italic">"{quote}"</p>
        <div className="flex items-center">
            <div className="w-12 h-12 bg-gradient-to-r from-blue-500 to-purple-600 rounded-full flex items-center justify-center text-white text-xl font-bold">
                {name[0]}
            </div>
            <div className="ml-4">
                <p className="font-bold text-gray-800 dark:text-gray-100">{name}</p>
                <p className="text-gray-600 dark:text-gray-400">{company}</p>
            </div>
        </div>
    </motion.div>
);

const CTASection = () => (
    <motion.section
        initial={{opacity: 0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.8}}
        className="text-center mb-16 relative overflow-hidden"
    >
        <motion.div
            className="absolute inset-0 z-0"
            animate={{
            }}
            transition={{ duration: 10, repeat: Infinity, repeatType: 'reverse' }}
        />
        <h2 className="text-5xl sm:text-6xl lg:text-7xl font-black mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 relative z-10">
            Bereit für Ihre digitale Revolution?
        </h2>
        <motion.p
            className="max-w-3xl mx-auto text-xl sm:text-2xl text-gray-700 dark:text-gray-300 mb-12 leading-relaxed relative z-10"
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{delay: 0.3, duration: 0.5}}
        >
            Lassen Sie uns gemeinsam Ihre Vision in eine atemberaubende digitale Realität umsetzen.
            Kontaktieren Sie uns noch heute für ein unverbindliches Gespräch über Ihr Projekt und
            erleben Sie, wie wir Ihre Online-Präsenz transformieren.
        </motion.p>
        <motion.a
            href="#kontakt"
            whileHover={{scale: 1.05}}
            whileTap={{scale: 0.95}}
            className="inline-flex items-center bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-5 px-10 rounded-full transition-all transform hover:shadow-xl text-xl relative overflow-hidden group z-10"
        >
            <span className="relative z-10">Jetzt Kontakt aufnehmen</span>
            <motion.div
                className="absolute inset-0 bg-white opacity-0 group-hover:opacity-20 transition-opacity duration-300"
                initial={false}
                animate={{ scale: [1, 1.5], opacity: [0.5, 0] }}
                transition={{ duration: 1, repeat: Infinity }}
            />
            <ArrowRight className="w-6 h-6 ml-2 relative z-10"/>
        </motion.a>
    </motion.section>
);

export default LandingPage;