import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sun, Moon, Book, ShoppingCart, Truck, CreditCard, RefreshCw, HelpCircle, ChevronDown } from 'lucide-react';


interface AlertProps {
    children: React.ReactNode;
    className?: string;
}

interface AlertTitleProps {
    children: React.ReactNode;
    className?: string;
}

interface AlertDescriptionProps {
    children: React.ReactNode;
    className?: string;
}

export const Alert: React.FC<AlertProps> = ({ children, className = '' }) => {
    return (
        <div className={`mb-8 bg-gradient-to-r from-blue-50 to-purple-50 dark:from-blue-900/50 dark:to-purple-900/50 border-l-4 border-indigo-500 dark:border-indigo-400 p-4 rounded-lg ${className}`}>
            {children}
        </div>
    );
};

export const AlertTitle: React.FC<AlertTitleProps> = ({ children, className = '' }) => {
    return (
        <h2 className={`text-lg font-semibold text-indigo-700 dark:text-indigo-300 ${className}`}>
            {children}
        </h2>
    );
};

export const AlertDescription: React.FC<AlertDescriptionProps> = ({ children, className = '' }) => {
    return (
        <p className={`text-indigo-600 dark:text-indigo-200 ${className}`}>
            {children}
        </p>
    );
};



const TermsAndConditions = () => {
    const [isDarkMode] = useState(false);
    const [expandedSection, setExpandedSection] = useState<number | null>(null);

    const sections = [
        { title: "1. Geltungsbereich", icon: <Book size={24} />, content: "Diese AGB regeln die Geschäftsbeziehung zwischen uns und Ihnen als Kunde. Sie gelten für alle Bestellungen in unserem Online-Shop." },
        { title: "2. Vertragsschluss", icon: <ShoppingCart size={24} />, content: "Ihre Bestellung stellt ein Angebot dar. Der Vertrag kommt zustande, wenn wir dieses Angebot durch Versand einer Auftragsbestätigung annehmen." },
        { title: "3. Lieferung", icon: <Truck size={24} />, content: "Die Lieferung erfolgt innerhalb Deutschlands. Lieferzeiten sind unverbindlich, sofern nicht ausdrücklich vereinbart. Bei Verzögerungen werden Sie umgehend informiert." },
        { title: "4. Preise und Zahlung", icon: <CreditCard size={24} />, content: "Alle Preise verstehen sich inkl. MwSt. Versandkosten werden separat ausgewiesen. Die Zahlung ist mit Vertragsschluss fällig." },
        { title: "5. Widerrufsrecht", icon: <RefreshCw size={24} />, content: "Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Frist beginnt mit Erhalt der Ware." },
        { title: "6. Gewährleistung", icon: <HelpCircle size={24} />, content: "Es gelten die gesetzlichen Gewährleistungsrechte. Bei Mängeln haben Sie das Recht auf Nacherfüllung, Rücktritt oder Minderung." },
    ];

    return (
        <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
            <div className="min-h-screen transition-colors duration-500 py-12 px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="max-w-4xl mx-auto bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden"
                >
                    <div className="p-8">
                        <div className="flex justify-between items-center mb-8">
                            <motion.h1
                                initial={{x: -20}}
                                animate={{x: 0}}
                                className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 dark:text-white flex items-center gap-3"
                            >
                                <Book className="text-indigo-600 dark:text-indigo-400" size={40}/>
                                <span
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-purple-600 dark:from-indigo-400 dark:to-purple-500">
        Allgemeine Geschäftsbedingungen
    </span>
                            </motion.h1>
                        </div>

                        <Alert
                            className="mb-8 bg-gradient-to-r from-blue-50 to-purple-50 dark:from-blue-900/50 dark:to-purple-900/50 border-l-4 border-indigo-500 dark:border-indigo-400">
                            <AlertTitle className="text-lg font-semibold text-indigo-700 dark:text-indigo-300">Wichtiger
                                Hinweis</AlertTitle>
                            <AlertDescription className="text-indigo-600 dark:text-indigo-200">
                            Diese AGB wurden zuletzt am 21.10.2024 aktualisiert. Bitte lesen Sie sie sorgfältig durch.
                            </AlertDescription>
                        </Alert>

                        <div className="space-y-6">
                            {sections.map((section, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                    className="border-b border-indigo-200 dark:border-indigo-700 pb-4"
                                >
                                    <button
                                        onClick={() => setExpandedSection(expandedSection === index ? null : index)}
                                        className="w-full text-left"
                                    >
                                        <div className="flex items-center justify-between">
                                            <h2 className="text-xl font-semibold text-indigo-700 dark:text-indigo-300 flex items-center gap-3">
                        <span className="p-2 bg-indigo-100 dark:bg-indigo-800 rounded-full">
                          {section.icon}
                        </span>
                                                {section.title}
                                            </h2>
                                            <ChevronDown
                                                className={`text-indigo-500 dark:text-indigo-400 transform transition-transform duration-300 ${
                                                    expandedSection === index ? 'rotate-180' : ''
                                                }`}
                                            />
                                        </div>
                                    </button>
                                    <AnimatePresence>
                                        {expandedSection === index && (
                                            <motion.div
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: 'auto' }}
                                                exit={{ opacity: 0, height: 0 }}
                                                transition={{ duration: 0.3 }}
                                                className="mt-4 text-gray-700 dark:text-gray-300 pl-12"
                                            >
                                                <p>{section.content}</p>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </motion.div>
                            ))}
                        </div>

                        <motion.div
                            className="mt-12 bg-gradient-to-r from-indigo-50 to-purple-50 dark:from-indigo-900/20 dark:to-purple-900/20 p-8 rounded-2xl shadow-inner"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.5 }}
                        >
                            <h2 className="text-2xl font-semibold mb-4 text-indigo-700 dark:text-indigo-300">Haben Sie Fragen zu unseren AGB?</h2>
                            <p className="mb-6 text-indigo-600 dark:text-indigo-200">
                                Unser engagiertes Kundenservice-Team steht Ihnen jederzeit für Rückfragen zur Verfügung.
                            </p>
                            <motion.a
                                whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(79, 70, 229, 0.5)" }}
                                whileTap={{ scale: 0.95 }}
                                href="mailto:agb@example.com"
                                className="inline-block px-8 py-4 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-full font-semibold hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 shadow-lg"
                            >
                                Kontakt aufnehmen
                            </motion.a>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default TermsAndConditions;